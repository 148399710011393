/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { SerializedStyles, jsx, css } from "@emotion/react";
import { Fragment, useRef } from "react";
import {
  Button,
  Dropdown,
  Field,
  ServiceTypeahead,
  Spacer,
  Text,
  ToggleSwitch,
  TooltipWrapper,
  Typeahead,
  VisuallyHidden,
} from "@zapier/design-system";

import { useWorkflowElement } from "./hooks/useWorkflowElement";
import { Category } from "src/pages/embed/workflow";
import {
  normalizeFilterable,
  onlyExcludedFilterables,
  onlyIncludedFilterables,
} from "src/utils/filterable";

function noop(): void {
  // Do nothing; used as an initial value
}

const styleFlexWrap = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  maxWidth: 300,
  gap: 5,
});

interface AppSearchToggleProps {
  categoriesList: Category[];
  clientLoaded: boolean;
  containerClassName: SerializedStyles;
  contentWrapperClassName: SerializedStyles;
}

export function AppSearchToggle({
  categoriesList,
  clientLoaded,
  containerClassName,
  contentWrapperClassName,
}: AppSearchToggleProps) {
  // Object with functions used to clear the `<ServiceTypeahead>` elements that
  // are used as multi-selects with button labels beneath them
  const clearRefs = useRef({
    app: noop,
    category: noop,
    excludedCategory: noop,
  });

  const workflowElement = useWorkflowElement();

  const showTemplates =
    workflowElement.elementConfig?.showTemplates ??
    workflowElement.defaultConfig.showTemplates;
  const appSearchBarDisplay =
    workflowElement.elementConfig?.appSearchBarDisplay ??
    workflowElement.defaultConfig.appSearchBarDisplay;
  const appExclusions =
    workflowElement.elementConfig?.appExclusions ??
    workflowElement.defaultConfig.appExclusions;
  const appCategories =
    workflowElement.elementConfig?.appCategories ??
    workflowElement.defaultConfig.appCategories;

  return (
    <div css={containerClassName}>
      <div css={contentWrapperClassName}>
        <Text
          color="neutral800"
          fontWeight={600}
          tag="h2"
          type="paragraph1ShortBold"
        >
          App search
        </Text>

        <TooltipWrapper
          zIndex={10}
          allowMultilineTooltip={true}
          content={
            !showTemplates
              ? "App search cannot be shown when popular workflows are hidden as app search filters the results in popular workflows."
              : ""
          }
          position="southeast"
        >
          {({ childProps }) => (
            <div data-testid="wfe:appSearchSwitch">
              <ToggleSwitch
                {...childProps}
                checked={
                  appSearchBarDisplay !== false && showTemplates === true
                }
                checkedText="Show"
                disabled={!showTemplates}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  workflowElement.setGeneratorProperty(
                    "appSearchBarDisplay",
                    event.target.checked,
                  )
                }
                showDisabledTooltip={false}
                uncheckedText="Hide"
              />
            </div>
          )}
        </TooltipWrapper>
      </div>
      {appSearchBarDisplay !== false && showTemplates && (
        <Fragment>
          <Spacer height={25} />
          <Text
            tag="h3"
            fontWeight={600}
            type="paragraph1ShortBold"
            color="neutral800"
            margin="0 0 10px 0"
          >
            Featured apps
          </Text>
          {clientLoaded && (
            <>
              <Field
                label="Number"
                renderInput={(inputProps) => (
                  <Dropdown
                    {...inputProps}
                    inputId={inputProps.id}
                    items={[{ label: "Hide", value: 0 }].concat(
                      [2, 4, 6, 8, 10].map((n) => ({
                        label: `${n}`,
                        value: n,
                      })),
                    )}
                    onChange={(option) => {
                      workflowElement.setGeneratorProperty(
                        "appLimit",
                        option.value,
                      );
                    }}
                    placeholder="Hide"
                  />
                )}
              />
              <Spacer height={10} />
              <Field
                label="Excluded"
                renderInput={() => (
                  <ServiceTypeahead
                    excludeUpcoming={true}
                    id="excludeApp"
                    menuAriaLabel="Exclude an app"
                    onChange={(app?: { name: string; slug: string }) => {
                      if (!app?.slug) {
                        return;
                      }
                      if (
                        appExclusions
                          .map((item) => item.slug)
                          .includes(app.slug)
                      ) {
                        return;
                      }
                      workflowElement.setGeneratorProperty("appExclusions", [
                        ...appExclusions,
                        app,
                      ]);
                      clearRefs.current.app();
                    }}
                    placeholder="Exclude an app"
                    setClearSelection={(f) => {
                      clearRefs.current.app = f as () => void;
                      return f;
                    }}
                  />
                )}
              />
              <Spacer height={5}></Spacer>
              <VisuallyHidden>Excluded apps</VisuallyHidden>
              <div css={styleFlexWrap}>
                {appExclusions.map((app) => (
                  <Button
                    key={app.slug}
                    color="primary-alt"
                    iconBefore="x"
                    size="xsmall"
                    onClick={() => {
                      workflowElement.setGeneratorProperty(
                        "appExclusions",
                        appExclusions.filter((item) => item.slug !== app.slug),
                      );
                    }}
                  >
                    {app.name}
                  </Button>
                ))}
              </div>
            </>
          )}
          <Spacer height={20} />
          <Text
            tag="h3"
            fontWeight={600}
            type="paragraph1ShortBold"
            color="neutral800"
            margin="0 0 10px 0"
          >
            App categories
          </Text>

          {clientLoaded && (
            <>
              <Field
                label="Included"
                renderInput={() => (
                  <Typeahead
                    getLabelForItem={(cat: Category) => cat.title}
                    id="includedCategories"
                    items={categoriesList}
                    menuAriaLabel="Include Categories"
                    setClearSelection={(f) => {
                      clearRefs.current.category = f as () => void;
                      return f;
                    }}
                    onChange={(category?: { title: string; slug: string }) => {
                      clearRefs.current.category();
                      if (!category?.slug) {
                        return;
                      }
                      if (
                        appCategories
                          .map((item) => item.operator + item.slug)
                          .includes(category.slug)
                      ) {
                        return;
                      }
                      workflowElement.setGeneratorProperty(
                        "appCategories",
                        normalizeFilterable([
                          ...appCategories,
                          { ...category, operator: "" },
                        ]),
                      );
                    }}
                    placeholder="Include a category"
                  />
                )}
              />
              <Spacer height={5}></Spacer>
              <div css={styleFlexWrap}>
                {onlyIncludedFilterables(appCategories).map((category, i) => (
                  <div css={styleFlexWrap} key={category.slug}>
                    {i > 0 && <span>and</span>}
                    <Button
                      color="primary-alt"
                      iconBefore="x"
                      size="xsmall"
                      onClick={() => {
                        workflowElement.setGeneratorProperty(
                          "appCategories",
                          appCategories.filter(
                            (item) => item.slug !== category.slug,
                          ),
                        );
                      }}
                    >
                      {category.title}
                      <VisuallyHidden>
                        , Remove included category
                      </VisuallyHidden>
                    </Button>
                  </div>
                ))}
              </div>
              <Spacer height={10}></Spacer>
              <Field
                label="Excluded"
                renderInput={() => (
                  <Typeahead
                    getLabelForItem={(cat: Category) => cat.title}
                    id="excludedCategories"
                    items={categoriesList}
                    menuAriaLabel="Exclude Categories"
                    setClearSelection={(f) => {
                      clearRefs.current.excludedCategory = f as () => void;
                      return f;
                    }}
                    onChange={(category?: Category) => {
                      clearRefs.current.excludedCategory();
                      if (!category?.slug) {
                        return;
                      }
                      workflowElement.setGeneratorProperty(
                        "appCategories",
                        normalizeFilterable([
                          ...appCategories,
                          { ...category, operator: "-" },
                        ]),
                      );
                    }}
                    placeholder="Exclude a category"
                  />
                )}
              />
              <Spacer height={5}></Spacer>
              <div css={styleFlexWrap}>
                {onlyExcludedFilterables(appCategories).map((category, i) => (
                  <div css={styleFlexWrap} key={category.slug}>
                    {i > 0 && <span>and</span>}
                    <Button
                      color="primary-alt"
                      iconBefore="x"
                      size="xsmall"
                      onClick={() => {
                        workflowElement.setGeneratorProperty(
                          "appCategories",
                          appCategories.filter(
                            (item) => item.slug !== category.slug,
                          ),
                        );
                      }}
                    >
                      <span
                        // Hide from screen readers to prevent double negation
                        // in text which is confusing
                        aria-hidden="true"
                      >
                        NOT
                      </span>{" "}
                      {category.title}
                      <VisuallyHidden>
                        , Remove included category
                      </VisuallyHidden>
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
        </Fragment>
      )}
    </div>
  );
}
